import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { useContext, useEffect, useState } from "react";
import { API_CONSTANTS } from "../../constants/api-constants";
import axios from "axios";
import Footer from "../footer/index.jsx";
import Header from "../header/index.jsx";
import Subscription from "../subscription";
import FloatingInput from "../floatinginput";
import * as appmetric from "../appmetrics/index.js";
import paymentContext from "../../context/paymentContext";
import "react-toastify/dist/ReactToastify.css";
import "./index.css";
import moment from "moment";

const Login = () => {
  const [error, setError] = useState("");
  const [searchParams] = useSearchParams();
  const { src, setSrc, mobNum, setMobNum, setPlanDetails, setStoreDetails } =
    useContext(paymentContext);
  const navigate = useNavigate();

  const loginPlanId = searchParams.get("planId");
  const loginSrc = searchParams.get("src");

  useEffect(() => {
    if (loginSrc === "Web") {
      const dateTime = moment().format("YYYY-MM-DD HH:mm:ss");
      window.localStorage.setItem("openOnePage", dateTime);
      var onLocalStorageEvent = function (e) {
        if (e.key == "openOnePage") {
          window.localStorage.setItem("pageAlreadyOpen", dateTime);
        }
        if (e.key == "pageAlreadyOpen") {
          alert(
            "iRevo Subscription page session is active in another Window / Tab. Please Close the existing session and try."
          );
          window.location.href = "/error";
          return false;
        }
      };
      window.addEventListener("storage", onLocalStorageEvent, false);
    }
  }, []);

  useEffect(() => {
    setSrc(loginSrc);
    setMobNum(mobNum);
    if (loginSrc === "Web") {
      setPlanDetails({ planId: loginPlanId });
    }
  }, []);

  const fetchData = async (url, payload, partyId) => {
    let response;
    try {
      response = await axios.post(`${API_CONSTANTS}${url}`, payload);
      setError("");
      navigate("/verifyOTP");
    } catch (error) {
      setError(error.response.data.errorMsg);
    }
    return response;
  };

  const sendOTP = (partyId) => {
    const postUrl = "/subscription/send-otp";
    const payload = {
      phone: mobNum,
      retailerPartyId: partyId,
    };

    fetchData(postUrl, payload, partyId);
  };

  const genrateOtp = async () => {
    if (mobNum === undefined || mobNum === null) {
      return setError("Enter valid Mobile Number");
    }
    if (mobNum.length < 10) {
      return setError("Enter 10 digit Mobile Number");
    }

    try {
      const storeDetailsUrl = `/subscription/checkValidSubscriptionPortal?mobileNumber=${mobNum}`;
      const storeDetailsAPI = await axios.get(
        `${API_CONSTANTS}${storeDetailsUrl}`
      );
      appmetric.addAppMetric(
        "8402",
        null,
        storeDetailsAPI.data.content.party_id,
        mobNum
      );

      setStoreDetails({
        partyId: storeDetailsAPI.data.content.party_id,
        storeName: storeDetailsAPI.data.content.storeName,
        ownerName: storeDetailsAPI.data.content.ownerName,
        emailId: storeDetailsAPI.data.content.email,
      });

      if (storeDetailsAPI.status === 200) {
        sendOTP(storeDetailsAPI.data.content.party_id);
      }
    } catch (error) {
      if (
        error?.response?.data?.errorCode === 406 &&
        error?.response?.data?.errorMsg === "More than one Store Party found"
      ) {
        return toast.error(
          "More than one pharmacy found for this number, so please contact administrator"
        );
      }

      if (
        error?.response?.data?.errorCode === 404 &&
        error?.response?.data?.errorMsg === "User not found"
      ) {
        sendOTP("100001");
        appmetric.addAppMetric("8402", null, "100001", mobNum);
      }
    }
  };

  const handleRetailerMobileNumber = (event) => {
    setError("");
    setMobNum(event.target.value.replace(/\D/gi, "").replace(/^0+/, ""));
  };

  const loginEnter = (event) => {
    if (event.keyCode === 13) {
      genrateOtp();
    }
  };

  return (
    <>
      {src === "App" ? (
        <Subscription />
      ) : (
        <>
          <Header />
          <div className="center text-center">
            <div className="paymentCard px-3 py-2">
              <div className="mobileGetStartedAlign mt-4 mb-5">
                Enter your mobile number to get started
              </div>

              <FloatingInput
                type="tel"
                id="mobileNumber"
                label="Mobile Number"
                maxLength={10}
                value={mobNum}
                onChange={handleRetailerMobileNumber}
                onKeyDown={(e) => loginEnter(e)}
              />

              <p className="mobileNumErrorAlign mt-2">{error}</p>

              <p className="text-secondary mt-4">
                Mobile number to Install and Signup iRevo app or mobile which
                already have iRevo account
              </p>

              <div style={{ marginTop: "100px" }}>
                <input
                  type="button"
                  className="getOTPButton py-1"
                  onClick={() => genrateOtp()}
                  value=" Get OTP"
                />
              </div>
            </div>

            <ToastContainer
              position="bottom-center"
              autoClose={2000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="dark"
              closeButton={false}
              className="p-4"
            />
          </div>
          <Footer />
        </>
      )}
    </>
  );
};

export default Login;
