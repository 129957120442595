import "./index.css";

const error = () => {
  return (
    <div className="center">
      iRevo Subscription page session is active in another Window / Tab. Please
      Close the existing session and try
    </div>
  );
};
export default error;
