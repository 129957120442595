import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { API_CONSTANTS } from "../../constants/api-constants";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import OTPInput from "otp-input-react";
import Header from "../header/index.jsx";
import Footer from "../footer/index.jsx";
import paymentContext from "../../context/paymentContext";
import * as appmetric from "../appmetrics/index.js";
import "react-toastify/dist/ReactToastify.css";
import "./index.css";

const VerifyOtp = () => {
  const [otp, setOtp] = useState("");
  const [error, setError] = useState("");
  const { mobNum, storeDetails, setStoreDetails } = useContext(paymentContext);
  const [display, setDisplay] = useState(false);
  const [countdown, setCountdown] = useState(60);
  const [resendOtp, setResendOtp] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    let timer;
    // if (resendOtp) {

    if (countdown > 0) {
      timer = setInterval(() => {
        setCountdown((prev) => prev - 1);
      }, 1000);
    }

    if (countdown === 0) {
      // Toast.show("OTP expired", NotifyType.ERROR);
      setDisplay(!display);
    }

    return () => clearInterval(timer);
    // }
  }, [resendOtp]);
  useEffect(() => {
    if (countdown === 0) {
      setResendOtp(false);
    } else {
      setResendOtp(true);
    }
  });
  const fetchData = async (url, payload) => {
    let response;
    try {
      response = await axios.put(`${API_CONSTANTS}${url}`, payload);
      setError("");
      checkParty();
    } catch (error) {
      setError(error.response.data.errorMsg);
    }
    return response;
  };

  const checkParty = async () => {
    let response;
    try {
      response = await axios.get(
        `${API_CONSTANTS}/check-mobile-registration?mobile=${mobNum}`
      );
      navigate("/signup");
    } catch (error) {
      navigate("/subscription");
    }
  };

  const verifyOtp = async () => {
    appmetric.addAppMetric(
      "8403",
      null,
      storeDetails.partyId || "100001",
      mobNum
    );
    if (!otp) {
      return setError("Please enter OTP");
    }

    const postUrl = "/subscription/verify-otp";
    const payload = {
      phone: mobNum,
      otp: otp,
      retailerPartyId: storeDetails.partyId || "100001",
    };

    await fetchData(postUrl, payload);
  };

  const genrateOtp = async () => {
    setResendOtp(true);
    setOtp("");
    setDisplay(!display);

    setCountdown(60);
    appmetric.addAppMetric(
      "8404",
      null,
      storeDetails.partyId || "100001",
      mobNum
    );
    if (mobNum === undefined || mobNum === null) {
      return setError("Enter valid Mobile Number");
    }
    if (mobNum.length < 10) {
      return setError("Enter 10 digit Mobile Number");
    }

    const postUrl = "/subscription/send-otp"; 
    const payload = {
      phone: mobNum,
      retailerPartyId: storeDetails.partyId || "100001",
    };

    const verifyOTPRespData = await sendOtp(postUrl, payload);
    return toast.success(verifyOTPRespData.data.errorMsg);
  };

  const sendOtp = async (url, payload) => {
    let response;
    try {
      response = await axios.post(`${API_CONSTANTS}${url}`, payload);
      setError("");
    } catch (error) {
      setError(error.response.data.errorMsg);
    }
    return response;
  };

  const changeOTP = (value) => {
    setError("");
    setOtp(value);
  };

  const clickChangeButton = () => {
    setStoreDetails({ partyId: "" });
    navigate(-1);
    appmetric.addAppMetric(
      "8405",
      null,
      storeDetails.partyId || "100001",
      mobNum
    );
  };

  const loginEnter = (event) => {
    if (event.keyCode === 13) {
      verifyOtp();
    }
  };
  const secondsToTime = (time) => {
    let minutes = Math.floor(time / 60);
    let seconds = Math.floor(time - minutes * 60);

    if (minutes < 10) minutes = "0" + minutes;
    if (seconds < 10) seconds = "0" + seconds;

    return `${minutes} : ${seconds}`;
  };
  return (
    <>
      <Header />
      <div className="center text-center">
        <div className="paymentCard py-2 px-1">
          <div className="otpGetStartedAlign mt-4 mb-5">
            Enter OTP to get started
          </div>
          <div
            className="fontWeigthAlign mb-3"
            onKeyDown={(e) => loginEnter(e)}
            id="otp-input"
          >
            Enter OTP
            <OTPInput
              value={otp}
              onChange={(value) => changeOTP(value)}
              autoFocus
              OTPLength={4}
              otpType="number"
              className="justify-content-center ms-2 mt-3"
              disabled={false}
            />
          </div>
          <div className="text-danger">{error}</div>
          <div className="mt-5">
            <button
              className={
                otp.length === 4
                  ? "getOTPButton py-1"
                  : "disableVerifyButton py-1"
              }
              onClick={() => verifyOtp()}
              disabled={otp.length === 4 ? false : true}
            >
              Verify
            </button>
          </div>
          {resendOtp ? (
            <div className="mt-4 fw-semibold">
              OTP will expires in <span className="expireCount">{secondsToTime(countdown)}</span>
            </div>
          ) : (
            <div className="mt-4">
              <span className="fontWeigthAlign">Didn't receive OTP?</span>&nbsp;
              <span
                role="button"
                className="fw-bold"
                style={{ color: "#7F1519" }}
                onClick={() => genrateOtp()}
              >
                Resend OTP
              </span>
            </div>
          )}
          <div className="my-3">
            <span className="fontWeigthAlign">Enter other number?</span>&nbsp;
            <span
              role="button"
              className="fw-bold"
              style={{ color: "#CE8A2E" }}
              onClick={() => clickChangeButton()}
            >
              Change
            </span>
          </div>
          <div
            className="text-secondary mt-5 px-3"
            style={{ fontSize: "14px" }}
          >
            <span className="fw-bolder">Note:</span>&nbsp;
            <span className="fw-normal">
              Do not press back button or refresh the page while processing your
              data
            </span>
          </div>
        </div>

        <ToastContainer
          position="bottom-center"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
          closeButton={false}
          className="p-4"
        />
      </div>
      <Footer />
    </>
  );
};
export default VerifyOtp;
