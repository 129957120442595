import React from "react";
import "./index.css";

function Footer() {

    const date = new Date();
    const currentYear = date.getFullYear();

    return (
        <>
            <footer className="footerAlign">
                <div className="footerTextAlign">
                    <div>
                        &#169; iRevo {currentYear}. All rights reserved.
                    </div>
                    <div className="policyUseAlign">
                        <div>
                            <a 
                                href="https://www.irevo.in/privacy-policy/" 
                                target="_blank" 
                                className="privacyPolicyAlign"
                            >
                                Privacy Policy
                            </a>
                        </div>
                        <div>
                            <a 
                                href="https://www.irevo.in/terms-of-use/" 
                                target="_blank" 
                                className="termsOfUseAlign"
                            >
                                Terms Of Use
                            </a>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
}

export default Footer;