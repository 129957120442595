import { useState } from "react";
import PaymentContext from "./paymentContext";

const PaymentProvider = ({ children }) => {
  const [plan, setPlan] = useState("");
  const [mobNum, setMobNum] = useState("");
  const [storeDetails, setStoreDetails] = useState({});
  const [paymentDetails, setPaymentDetails] = useState({});
  const [src, setSrc] = useState("");
  const [selectedPId, setSelectedPId] = useState("");
  const [planDetails, setPlanDetails] = useState({});
  const [selectedPlanOrignialPrice, setSelectedPlanOrignialPrice] = useState();
  const [addressDetails, setAddressDetails] = useState("");

  return (
    <PaymentContext.Provider
      value={{
        src,
        setSrc,
        plan,
        setPlan,
        mobNum,
        setMobNum,
        selectedPId,
        setSelectedPId,
        planDetails,
        setPlanDetails,
        storeDetails,
        setStoreDetails,
        paymentDetails,
        setPaymentDetails,
        addressDetails,
        setAddressDetails,
        selectedPlanOrignialPrice,
        setSelectedPlanOrignialPrice
      }}
    >
      {children}
    </PaymentContext.Provider>
  );
};

export default PaymentProvider;
