import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { API_CONSTANTS } from "../../constants/api-constants";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import Header from "../header/index.jsx";
import Footer from "../footer/index.jsx";
import FloatingInput from "../floatinginput";
import BeatLoader from "react-spinners/BeatLoader";
import paymentContext from "../../context/paymentContext";
import * as appmetric from "../appmetrics/index.js";
import "react-toastify/dist/ReactToastify.css";
import "./index.css";

const Signup = () => {
  const [pharmacyName, setPharmacyName] = useState("");
  const [retailerName, setRetailerName] = useState("");
  const [emailId, setEmailId] = useState("");
  const [gstinNumber, setGstinNumber] = useState("");
  const [state, setState] = useState("");
  const [stateId, setStateId] = useState("");
  const [addressForBilling, setAddressForBilling] = useState("");
  const [stateList, setStateList] = useState([]);
  const [displayCreateAccount, setDisplayCreateAccount] = useState(false);
  const { mobNum, storeDetails, setStoreDetails } = useContext(paymentContext);
  const navigate = useNavigate();

  const onRetailerNameChange = (e) => {
    setRetailerName(e.target.value.replace(/  +/g, " "));
  };

  const onPharmacyNameChange = (e) => {
    setPharmacyName(e.target.value.replace(/  +/g, " "));
  };

  const onEmailIdChange = (e) => {
    setEmailId(e.target.value.replace(/ /g, ""));
  };

  const ongstinNumberChange = (e) => {
    setGstinNumber(e.target.value);
    if (state) {
      setState("");
    }
  };

  const getStateCode = (event) => {
    if (event.target.value) {
      const gstinStateCode = event.target.value.slice(0, 2);
      const stateCode = stateList?.filter(
        (state) => state.stateCode === gstinStateCode
      );

      if (
        !/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9]{1}Z[a-zA-Z0-9]{1}$/.test(
          event.target.value
        )
      ) {
        setState("");
        return toast.error("Invalid GSTIN Number");
      } else if (stateCode[0]?.stateCode !== gstinStateCode) {
        setState("");
        return toast.error("Invalid GSTIN State Code");
      } else {
        const stateName = stateList?.filter(
          (state) => state.stateCode === gstinStateCode
        );

        if (stateName) {
          setStateId(stateName[0]?.stateId);
          setState(stateName[0]?.stateName);
        }
      }
    }
  };

  const onStateChange = (e) => {
    const getStateId = stateList?.filter(
      (state) => state.stateName === e.target.value
    );
    setState(e.target.value);
    setStateId(getStateId[0]?.stateId);

    if (gstinNumber) {
      const gstinStateCode = gstinNumber.slice(0, 2);
      const stateName = stateList?.filter(
        (state) => state.stateCode === gstinStateCode
      );

      if (stateName[0]?.stateName !== e.target.value) {
        setGstinNumber("");
      }
    }
  };

  const onAddressForBillingChange = (e) => {
    setAddressForBilling(
      e.target.value
        .replace(/[^A-Za-z0-9 , ( ) / : @ & % _ ' . -]/gi, "")
        .replace(/  +/g, "")
    );
  };

  useEffect(() => {
    getStateList();
  }, []);

  const getStateList = async () => {
    try {
      const stateList = `/state-list`;

      const stateListAPI = await axios.get(`${API_CONSTANTS}${stateList}`);

      if (stateListAPI.data.errorCode === 200) {
        setStateList(stateListAPI.data.content.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const partyCreation = async () => {
    appmetric.addAppMetric("8406", null, "100001", mobNum);
    if (!pharmacyName) {
      return toast.error("Pharmacy name cannot be empty");
    }

    if (pharmacyName.length < 3) {
      return toast.error("Pharmacy name should be more than 3 characters");
    }

    if (!retailerName) {
      return toast.error("Owner name cannot be empty");
    }

    if (retailerName.length < 3) {
      return toast.error("Owner name should be more than 3 characters");
    }

    if (!/^[A-Za-z0-9%&()+:/,.'@|-\s]*$/.test(retailerName)) {
      return toast.error("Owner name should be valid characters only");
    }

    if (!emailId) {
      return toast.error("Email Id cannot be empty");
    }

    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(emailId)) {
      return toast.error("Invalid Email Id");
    }

    if (!gstinNumber && !state) {
      return toast.error("GSTIN or State cannot be empty");
    }

    if (gstinNumber) {
      if (
        !/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9]{1}Z[a-zA-Z0-9]{1}$/.test(
          gstinNumber
        )
      ) {
        return toast.error("Invalid GSTIN Number");
      }
    }

    if (gstinNumber && state) {
      const gstinStateCode = gstinNumber.slice(0, 2);
      const stateCode = stateList?.filter(
        (state) => state.stateCode === gstinStateCode
      );

      if (stateCode[0].stateCode !== gstinStateCode) {
        return toast.error("GSTIN State Code is not matched with the State");
      }
    }

    if (addressForBilling) {
      if (addressForBilling.length < 3) {
        return toast.error("Address should be more than 3 characters");
      }

      if (
        !/^[A-Za-z0-9][A-Za-z0-9, ( ) / : @ & % _ ' . \s-]*$/.test(
          addressForBilling
        )
      ) {
        return toast.error("Please enter valid address");
      }
    }

    let response;
    let payload = {
      store_name: "",
      mobile: "",
      email: "",
      ownerName: "",
      gstinNo: "",
      state: "",
      storeAddress: "",
    };

    try {
      payload.store_name = pharmacyName.trim();
      payload.mobile = mobNum;
      payload.email = emailId.trim();
      payload.ownerName = retailerName.trim();
      payload.gstinNo = gstinNumber;
      payload.state = stateId;
      payload.storeAddress = addressForBilling.trim();

      setDisplayCreateAccount(true);

      response = await axios.post(
        `${API_CONSTANTS}/mposMicrositeOnboard`,
        payload
      );
      if (response.data.errorCode === 200) {
        setDisplayCreateAccount(false);
        setStoreDetails({
          partyId: response.data.content.partyId,
          storeName: pharmacyName,
          emailId: emailId,
          ownerName: retailerName,
        });
        navigate("/subscription");
      }
    } catch (error) {
      setDisplayCreateAccount(false);
      toast.error(error.response.data.errorMsg.replace("Store", "Pharmacy"));
    }
  };

  return (
    <>
      <Header />
      <div className="center text-center">
        <div className="paymentCard py-2 px-3 px-md-5">
          <div className="mt-3">Looks like you don't have an account</div>

          <div
            className="createAccountAlign mb-4"
            onClick={() =>
              appmetric.addAppMetric("8403", null, storeDetails.partyId, mobNum)
            }
          >
            Create account
          </div>

          <div className="mb-4">
            <FloatingInput
              type="text"
              id="pharmacyName"
              value={pharmacyName}
              onChange={onPharmacyNameChange}
              label="Pharmacy Name"
              maxLength={100}
              disabled={displayCreateAccount}
            />
          </div>

          <div className="mb-4">
            <FloatingInput
              type="text"
              id="retailerName"
              value={retailerName}
              onChange={onRetailerNameChange}
              label="Owner Name"
              maxLength={100}
              disabled={displayCreateAccount}
            />
          </div>

          <div className="mb-4">
            <FloatingInput
              type="text"
              id="emailId"
              value={emailId}
              onChange={onEmailIdChange}
              label="Email Id"
              maxLength={100}
              disabled={displayCreateAccount}
            />
          </div>

          <div className="mb-2">
            <FloatingInput
              type="text"
              id="gstinNumber"
              value={gstinNumber}
              onChange={ongstinNumberChange}
              label="GSTIN"
              maxLength={15}
              onBlur={getStateCode}
              disabled={displayCreateAccount}
            />
          </div>

          <div className="my-2">(OR)</div>

          <div className="floatingAlign mb-4">
            <select
              className="floatingSelectAlign"
              value={state}
              onChange={onStateChange}
              disabled={displayCreateAccount}
            >
              <option value="" selected>
                Select
              </option>
              {stateList?.map((state) => (
                <option value={state.stateName}>{state.stateName}</option>
              ))}
            </select>
            <label className="floatingLabelAlign">State</label>
          </div>

          <div className="mb-2">
            <FloatingInput
              type="text"
              id="addressForBilling"
              value={addressForBilling}
              onChange={onAddressForBillingChange}
              label="Address for Billing"
              maxLength={200}
              disabled={displayCreateAccount}
            />
          </div>

          <div
            className="mb-1 text-start text-secondary"
            style={{ fontSize: "14px" }}
          >
            <span className="fw-bold">Note:</span>&nbsp;
            <div>
              1. Please ensure that the correct GSTIN number is provided.
            </div>
            <div>
              2. Do not press back button or refresh the page while processing
              your data.
            </div>
            <div>3. These inputs will be used for invoice generation.</div>
          </div>

          <div className="my-2">
            {displayCreateAccount ? (
              <div className="sm:mt-4 flex items-center justify-center h-screen">
                <BeatLoader color="#7F1519" />
              </div>
            ) : (
              <button
                className="continuePaymentButton py-1"
                onClick={() => partyCreation()}
              >
                Create Account
              </button>
            )}
          </div>
        </div>

        <ToastContainer
          position="bottom-center"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
          closeButton={false}
          className="p-4"
        />
      </div>
      <Footer />
    </>
  );
};

export default Signup;
