import "./App.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Login from "./containers/login";
import VerifyOtp from "./containers/verifyOtp";
import Subscription from "./containers/subscription";
import Signup from "./containers/signup";
import ComparePlan from "./containers/compareplan";
import SubscriptionSuccess from "./containers/subscriptionsuccess";
import PaymentProvider from "./context/paymentProvider";
import Error from "./containers/error/error";
function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Login />,
    },
    {
      path: "/:paymentPlan",
      element: <Login />,
    },
    {
      path: "/verifyOTP",
      element: <VerifyOtp />,
    },
    {
      path: "/subscription",
      element: <Subscription />,
    },
    {
      path: "/signup",
      element: <Signup />,
    },
    {
      path: "/compareplan",
      element: <ComparePlan />,
    },
    {
      path: "/subscriptionsuccess",
      element: <SubscriptionSuccess />,
    },
    {
      path: "/error",
      element: <Error />,
    },
  ]);
  return (
    <PaymentProvider>
      <RouterProvider router={router} />
    </PaymentProvider>
  );
}

export default App;
