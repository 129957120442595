import React from "react";
import "./index.css";

function FloatingInput(props) {
  return (
    <>
      <div className="floatingGroupAlign">
        <input
          type={props.type}
          className="floatingInputAlign"
          id={props.id}
          maxLength={props.maxLength}
          onChange={props.onChange}
          value={props.value}
          autoComplete="off"
          disabled={props.disabled && props.disabled}
          onKeyDown={props?.onKeyDown}
          onBlur={props?.onBlur}
        />
        <label htmlFor={props.id}>
          <span>{props.label}</span>
          {
            (props.label !== "GSTIN" && props.label !== "Address for Billing") && <span className="text-danger">*</span>
          }
        </label>
      </div>
    </>
  );
}

export default FloatingInput;
