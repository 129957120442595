import { useCookies } from "react-cookie";
import { API_CONSTANTS } from "../../constants/api-constants";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import BeatLoader from "react-spinners/BeatLoader";
import * as appmetric from "../appmetrics/index.js";
import SignedIn from "../../assets/images/signedIn.png";
import paymentContext from "../../context/paymentContext";
import "./index.css";
import Header from "../header/index.jsx";
import Footer from "../footer/index.jsx";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Subscription = () => {
  const {
    src,
    setSrc,
    mobNum,
    setMobNum,
    planDetails,
    setPlanDetails,
    storeDetails,
    setStoreDetails,
    addressDetails,
    setAddressDetails,
    setPaymentDetails,
    selectedPlanOrignialPrice,
    setSelectedPlanOrignialPrice,
  } = useContext(paymentContext);
  let navigate = useNavigate();
  const [theObj, setTheObj] = useState(null);
  const [searchParams] = useSearchParams();
  const [validPlanId, setValidPlanId] = useState([]);
  const [displayValid, setDisplayValid] = useState(false);
  const [showCouponCode, setShowCouponCode] = useState(false);
  const [showCouponCodeModal, setShowCouponCodeModal] = useState(false);
  const subscriptionPlanId = searchParams.get("planId");
  const subscriptionPartyId = searchParams.get("partyId");
  const subscriptionSrc = searchParams.get("src");
  const subscriptionMobNum = searchParams.get("mobNo");
  const [extractedObject, setExtractedObject] = useState({});
  const [cookies, setCookie, removeCookie] = useCookies(["responseCookieName"]);
  const [couponCode, setCouponCode] = useState('')
  const [validCouponCode, setValidCouponCode] = useState('')
  const [applyButton, setApplyButton] = useState(false)
  
  useEffect(() => {
    if (src === "Web") {
      const dateTime = moment().format("YYYY-MM-DD HH:mm:ss");
      window.localStorage.setItem("openOnePage", dateTime);
      var onLocalStorageEvent = function (e) {
        if (e.key == "openOnePage") {
          window.localStorage.setItem("pageAlreadyOpen", dateTime);
        }
        if (e.key == "pageAlreadyOpen") {
          alert(
            "iRevo Subscription page session is active in another Window / Tab. Please Close the existing session and try."
          );
          window.location.href = "/error";
          return false;
        }
      };
      window.addEventListener("storage", onLocalStorageEvent, false);
    }
  }, []);

  useEffect(() => {
    if(couponCode.length === 0){
      setApplyButton(true);
    }
  }, [])
  

  useEffect(() => {
    const cookieValue = cookies["responseCookieName"] || "Cookie not found";
    let cookieArr = cookieValue.split("&");
    for (const data of cookieArr) {
      let splitedData = data.split("=");
      setExtractedObject((e) => ({ ...e, [splitedData[0]]: splitedData[1] }));
    }
  }, [cookies]);

  useEffect(() => {
    if (subscriptionSrc === "App") {
      setSrc(subscriptionSrc);
      setMobNum(subscriptionMobNum);
      setStoreDetails({ partyId: extractedObject.partyId });
    }
    information();
  }, [subscriptionPartyId]);


  const handleClick = () => {
    setShowCouponCode(s => !s);
    setShowCouponCodeModal(true);
  };

  const couponCodeClick = async () => {
    setApplyButton(true);
    try {
      const couponCodeUrl = `/subscription/verify-coupon?subId=${planDetails.planId}&coupon=${couponCode}`;
      const couponCodeAPI = await axios.get(`${API_CONSTANTS}${couponCodeUrl}`);
     
      if (couponCodeAPI.data.content.couponFlag) {
        setShowCouponCodeModal(false);
        
        setValidCouponCode(couponCode);

        const getPlanId = [];
      getPlanId.push(couponCodeAPI.data.content);
      setSelectedPlanOrignialPrice(getPlanId[0]?.originalPrice);
      setValidPlanId(getPlanId[0]?.planId);
      setPlanDetails({
        planId: getPlanId[0]?.planId,
        subscriptionId: getPlanId[0]?.subscriptionId,
        finalPrice: getPlanId[0]?.finalPrice,
        planValidity: getPlanId[0]?.planValidity,
        originalPrice: getPlanId[0]?.originalPrice.split(" ")[1],
        taxPercentage: getPlanId[0]?.taxPercentage,
        discountedPrice: getPlanId[0]?.discountedPrice,
        discountType: getPlanId[0]?.discountType,
        taxPrice: getPlanId[0]?.taxPrice.split(" ")[1],
        roundOff: getPlanId[0]?.roundOff,
        planPrice: getPlanId[0]?.planPrice.split(" ")[1],
        discountValue: getPlanId[0]?.discountValue,
        displayContent: getPlanId[0]?.displayContent != null ? getPlanId[0]?.displayContent : ""
      });
      toast.success("Your Coupon Code has been successfully added");
      }else{
        toast.error("The Coupon Code has Already Used !!!.");
        setTimeout(() => {
          subscriptionDetails(storeDetails.partyId);
        setValidCouponCode("");
        setCouponCode("");
        setShowCouponCodeModal(true);
        }, 3000);
      }
    } catch (error) {
      toast.error(error.response.data.errorMsg);
      setTimeout(() => {
        subscriptionDetails(storeDetails.partyId);
      setValidCouponCode("");
      setCouponCode("");
      setShowCouponCodeModal(true);
      }, 3000);
      
    }
  }

  const information = async () => {
    try {
      const storeDetailsUrl = `/subscription/checkValidSubscription?storePartyId=${subscriptionPartyId || storeDetails.partyId
        }`;
      const storeDetailsAPI = await axios.get(
        `${API_CONSTANTS}${storeDetailsUrl}`
      );
      setStoreDetails({
        partyId: storeDetailsAPI.data.content.party_id,
        storeName: storeDetailsAPI.data.content.storeName,
        emailId: storeDetailsAPI.data.content.email,
        ownerName: storeDetailsAPI.data.content.ownerName,
        gstin: storeDetailsAPI.data.content.gstin,
      });

      setAddressDetails({
        billing_address: storeDetailsAPI?.data?.content?.address || "address",
        billing_city: storeDetailsAPI?.data?.content?.city || "city",
        billing_state: storeDetailsAPI?.data?.content?.state || "state",
        billing_zip: storeDetailsAPI?.data?.content?.pincode || "999999",
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (subscriptionPartyId) {
      subscriptionDetails(subscriptionPartyId);
    } else if (storeDetails.partyId) {
      subscriptionDetails(storeDetails.partyId);
    }
  }, [subscriptionPartyId, storeDetails.partyId]);

  const subscriptionDetails = async (partyId) => {
    setDisplayValid(true);
    try {
      const subscriptionDetailsUrl = `/subscription/subscriptionDetails?storePartyId=${partyId}`;
      const response = await axios.get(
        `${API_CONSTANTS}${subscriptionDetailsUrl}`
      );

      const getPlanId = response.data.content.subscriptionPlanDetails.filter(
        (plan) => {
          if (subscriptionSrc === "App") {
            return plan.planId === subscriptionPlanId;
          } else {
            return plan.planId === planDetails.planId;
          }
        }
      );

      setSelectedPlanOrignialPrice(getPlanId[0]?.originalPrice);
      setValidPlanId(getPlanId[0]?.planId);
      setPlanDetails({
        planId: getPlanId[0]?.planId,
        subscriptionId: getPlanId[0]?.subscriptionId,
        finalPrice: getPlanId[0]?.finalPrice,
        planValidity: getPlanId[0]?.planValidity,
        originalPrice: getPlanId[0]?.originalPrice.split(" ")[1],
        taxPercentage: getPlanId[0]?.taxPercentage,
        discountedPrice: getPlanId[0]?.discountedPrice,
        discountType: getPlanId[0]?.discountType,
        taxPrice: getPlanId[0]?.taxPrice.split(" ")[1],
        roundOff: getPlanId[0]?.roundOff,
        planPrice: getPlanId[0]?.planPrice.split(" ")[1],
        discountValue: getPlanId[0]?.discountValue,
        displayContent:""
      });
    } catch (error) {
      console.log("error", error);
    }
    setTimeout(() => {
      setDisplayValid(false);
    }, 1000);
  };

  const continuePayment = async () => {
    appmetric.addAppMetric("8408", null, storeDetails.partyId, mobNum);
    localStorage.setItem("partyId", storeDetails.partyId);
    localStorage.setItem("storeName", storeDetails.storeName);
    localStorage.setItem("storeDetails", storeDetails);
    localStorage.setItem("amount", planDetails.finalPrice);
    localStorage.setItem("planId", planDetails.subscriptionId);
    localStorage.setItem("planTypeId", planDetails.planId);
    localStorage.setItem("userLoginId", mobNum);
    localStorage.setItem("ownerName", storeDetails.ownerName);
    localStorage.setItem("planValidity", planDetails.planValidity);
    localStorage.setItem("couponCode", validCouponCode);

    let response;
    // let payload;
    try {
      // payload = {
      //   merchant_id: "2922233",
      //   order_id: "55555",
      //   currency: "INR",
      //   amount: "1.0",
      //   redirect_url: "https://payments.irevo.in/",
      //   cancel_url: "https://payments.irevo.in/",
      //   language: "EN",
      //   billing_name: "Raja",
      //   billing_address: "Santacruz",
      //   billing_city: "Mumbai",
      //   billing_state: "MH",
      //   billing_zip: "400054",
      //   billing_country: "India",
      //   billing_tel: "7338067974",
      //   billing_email: "sharmilee.s@tarnea.com",
      //   delivery_name: "Sam",
      //   delivery_address: "Vile",
      //   delivery_city: "Mumbai",
      //   delivery_state: "Maharashtra",
      //   delivery_zip: "400038",
      //   delivery_country: "India",
      //   delivery_tel: "0123456789",
      //   merchant_param1: "",
      //   merchant_param2: "",
      //   merchant_param3: "",
      //   merchant_param4: "",
      //   merchant_param5: "",
      //   promo_code: "",
      //   customer_identifier: "",
      // };

      const initiatePaymentUrl = "/subscription/initiate/payment";
      const initiatePaymentPayload = {
        planId: planDetails.subscriptionId,
        planTypeId: planDetails.planId,
        amount: planDetails.finalPrice,
        userLoginId: mobNum,
        storePartyId: storeDetails.partyId,
      };
      const initiatePaymentAPI = await axios.post(
        `${API_CONSTANTS}${initiatePaymentUrl}`,
        initiatePaymentPayload
      );

      if (initiatePaymentAPI.data.errorCode === 200) {
        setPaymentDetails({
          paymentId: initiatePaymentAPI.data.content.subscriptionPaymentId,
          orderId:
            initiatePaymentAPI.data.content.subscriptionPaymentId.slice(-6),
        });
      }

      let cookieObj = {
        amount: planDetails.finalPrice,
        order_id:
          initiatePaymentAPI.data.content.subscriptionPaymentId.slice(-6),
        source: validCouponCode ? src + "-"+ validCouponCode: src,
        subscriptionPaymentId:
          initiatePaymentAPI.data.content.subscriptionPaymentId,
        partyId: storeDetails.partyId,
        planId: planDetails.planId,
        // couponCode:,
        billing_name: storeDetails.ownerName || storeDetails.storeName,
        billing_tel: mobNum,
        billing_email: storeDetails.emailId || "admin@tarnea.com",
        billing_address: addressDetails.billing_address || "address",
        billing_city: addressDetails.billing_city || "city",
        billing_state: addressDetails.billing_state || "state",
        billing_zip: addressDetails.billing_zip || "999999",
      };
      document.cookie = ` yourCookieName=${JSON.stringify(
        cookieObj
      )}; domain=.irevo.in; path:/about `;
      // )}; domain=localhost; path:/about `;

      // Localhost URL
      // window.location.href = "http://localhost:3010/about";
      // Dev URL
      // window.location.href = "https://stagingsrv-payments.irevo.in/about";
      // Production URL
      window.location.href = "https://paymentsrv.irevo.in/about";

      setTheObj({ __html: response.data });
    } catch (error) {
      console.log("Error Response", error);
    }
  };

  const clickCompareOtherPlanButton = () => {
    appmetric.addAppMetric("8407", null, storeDetails.partyId, mobNum);
    navigate("/compareplan");
  };

  useEffect(() => {
    if (theObj) {
      document.redirect.submit();
    }
  }, [theObj]);

  const subscriptionId = planDetails.subscriptionId;

  const displayPlanName =
    subscriptionId === "RET_SUBSCR_PLAN1"
      ? "DIY"
      : subscriptionId === "RET_SUBSCR_PLAN2"
        ? "Silver"
        : subscriptionId === "RET_SUBSCR_PLAN3"
          ? "Gold"
          : subscriptionId === "RET_SUBSCR_PLAN4"
            ? "Platinum"
            : null;

  const deleteCookie = (cookieName) => {
    document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  };

  const handleDeleteClick = () => {
    deleteCookie("responseCookieName");
  };


  const couponCodeOnChange = (e)=>{
    setCouponCode((e.target.value).trim()); 
    setApplyButton(false); 
    e.target.value.length === 0 ? setApplyButton(true) : setApplyButton();
    if(e.target.value.length === 0){
      setValidCouponCode("")
      if (subscriptionPartyId) {
        subscriptionDetails(subscriptionPartyId);
      } else if (storeDetails.partyId) {
        subscriptionDetails(storeDetails.partyId);
      }
      setShowCouponCodeModal(true);
    }
  }
  
  return (
    <>
    
      {theObj ? (
        <>
          <div dangerouslySetInnerHTML={theObj} />
        </>
      ) : (
        <>
          {src === "Web" && <Header />}
          <div className="center text-center">
            <div className="paymentCard p-2">
              <div className="my-3">
                <img src={SignedIn} alt="Success" width={200} height={200} />
              </div>

              <div className="mb-2">Welcome {storeDetails.storeName}!</div>

              {displayValid ? (
                <div className="sm:mt-4 flex items-center justify-center h-screen">
                  <BeatLoader color="#7F1519" />
                </div>
              ) : validPlanId?.length ? (
                <>
                  <div className={planDetails.displayContent ? "fw-bold displayPlanContentAlign": "fw-bold displayPlanAlign"}>
                    {selectedPlanOrignialPrice &&
                      planDetails &&
                      `You have selected ${displayPlanName} plan for ${planDetails.planValidity} ${planDetails.displayContent} worth ${selectedPlanOrignialPrice} + 18% GST`}
                  </div>
                  {
                    !showCouponCode ?
                      <div className="my-1 fw-bold">
                        Have a coupon? <button className="btn p-0" onClick={handleClick}>
                          <a href="javascript:void(0)">Click here</a></button>
                      </div>
                      :
                      <div className="d-flex justify-content-center align-items-center mx-5 my-2">
                        <input type="text" name="couponcode" id="couponcode" className="form-control w-50" onChange={(e) => couponCodeOnChange(e)} value={couponCode} placeholder="Enter Coupon Code"/>
                        <button className="btn btn-coupon ms-2" onClick={() => couponCodeClick()} disabled={applyButton} >Apply</button>
                      </div>
                  }

                      <ToastContainer
                        position="bottom-center"
                        autoClose={2000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="dark"
                        closeButton={false}
                        className="p-4"
                      />

                  <span
                    role="button"
                    className="compareOtherPlanAlign mt-2"
                    onClick={() => clickCompareOtherPlanButton()}
                  >
                    Compare other plan
                  </span>

                  <div className="mt-3">
                    {showCouponCodeModal ?
                      <button type="button" className="clickContinuePaymentButton py-1" data-bs-toggle="modal" data-bs-target="#exampleModal">
                        Continue payment
                      </button>
                      :
                      <button
                        className="clickContinuePaymentButton py-1"
                        onClick={() => {
                          continuePayment();
                          handleDeleteClick();
                        }}
                      >
                        Continue payment
                      </button>
                    }
                  </div>

                  <div className="text-danger mt-3">
                    <span className="fw-bolder">Note:</span>&nbsp;
                    <span className="fw-normal">
                      Do not press back button or refresh the page while
                      processing the payment
                    </span>
                  </div>
                </>
              ) : (
                <div className="text-danger fw-bold mt-4">
                  Please Select Valid Plan
                </div>
              )}
            </div>
          </div>
          {src === "Web" && <Footer />}

          <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">Payment</h5>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                Looks like you entered a wrong code, re-enter the correct code or else click 'Continue' to proceed without the code
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn-coupon" data-bs-dismiss="modal">Back</button>
                  <button type="button" onClick={() => {
                    continuePayment();
                    handleDeleteClick();
                  }} className="clickContinuePaymentButton py-1" style={{ width: "100px" }}>Continue</button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Subscription;
