import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { API_CONSTANTS } from "../../constants/api-constants";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import moment from "moment";
import Header from "../header";
import Footer from "../footer";
import BeatLoader from "react-spinners/BeatLoader";
import paymentContext from "../../context/paymentContext";
import Playstore from "../../assets/images/Playstore.png";
import SuccessfulPayment from "../../assets/images/SuccessfulPayment.png";
import PaymentIncomplete from "../../assets/images/PaymentIncomplete.png";
import "react-toastify/dist/ReactToastify.css";
import "./index.css";

const SubscriptionSuccess = () => {
  const [extractedObject, setExtractedObject] = useState({});
  const [cookies, setCookie, removeCookie] = useCookies(["responseCookieName"]);
  const [invoiceLink, setInvoiceLink] = useState("");
  // const [planData, setPlanData] = useState({});
  const { mobNum, setMobNum, setSrc, setStoreDetails, setPlanDetails } =
    useContext(paymentContext);
  const navigate = useNavigate();
  const dateTime = moment().format("YYYY-MM-DD HH:mm:ss");

  useEffect(() => {
    if ((extractedObject.merchant_param5)?.split("-")[0] === "Web") {
      const dateTime = moment().format("YYYY-MM-DD HH:mm:ss");
      window.localStorage.setItem("openOnePage", dateTime);
      var onLocalStorageEvent = function (e) {
        if (e.key == "openOnePage") {
          window.localStorage.setItem("pageAlreadyOpen", dateTime);
        }
        if (e.key == "pageAlreadyOpen") {
          alert(
            "iRevo Subscription page session is active in another Window / Tab. Please Close the existing session and try."
          );
          window.location.href = "/error";
          return false;
        }
      };
      window.addEventListener("storage", onLocalStorageEvent, false);
    }
  }, []);

  useEffect(() => {
    const cookieValue = cookies["responseCookieName"] || "Cookie not found";
    if (cookies != "") {
      let cookieArr = cookieValue?.split("&");
      for (const data of cookieArr) {
        let splitedData = data?.split("=");
        setExtractedObject((e) => ({ ...e, [splitedData[0]]: splitedData[1] }));
      }
    }
  }, [cookies]);

  useEffect(() => {
    setSrc((extractedObject.merchant_param5)?.split("-")[0]);
  }, [extractedObject.merchant_param5]);

  const redirectAndroid = () => {
    window.Android.gotoActivity();
  };

  useEffect(() => {
    if (
      extractedObject.order_status === "Success"
      // ||      extractedObject.order_status === "Initiated"
    ) {
      setInvoiceLink(extractedObject.invoicePath);
      if (extractedObject.billing_email !== "admin@tarnea.com") {
        return toast.success(
          `Transaction Successful, Email has been sent to ${extractedObject.billing_email}`
        );
      }
      // generateEInvoice(
      //   extractedObject.subscriptionId,
      //   extractedObject.subscriptionActivityId
      // );
    }
  }, [extractedObject.merchant_param3]);
  // useEffect(() => {
  //   if (extractedObject.merchant_param3) {
  //     subscriptionDetails();
  //   }
  // }, [extractedObject.merchant_param3]);

  const subscriptionDetails = async () => {
    try {
      const subscriptionDetailsUrl = `/subscription/subscriptionDetails?storePartyId=${extractedObject.merchant_param3}`;
      const response = await axios.get(
        `${API_CONSTANTS}${subscriptionDetailsUrl}`
      );

      response.data.content.subscriptionPlanDetails.filter((plan) => {
        if (plan.subscriptionId !== "RET_SUBSCR_PLAN1") {
          if (plan.planId === extractedObject.merchant_param4) {
            // setPlanData(plan);
            updateSubscription();

            if (
              extractedObject.order_status === "Success" 
              // || extractedObject.order_status === "Initiated" 
            ) {
              createSubcription(plan);
            }
          }
        }
      });
    } catch (error) {
      console.log("error", error);
    }
  };

  const updateSubscription = async () => {
    try {
      const updateSub = "/subscription/update/payment";
      let payload = {
        storePartyId: extractedObject.merchant_param3,
        subscriptionPaymentId: extractedObject.merchant_param2,
        paymentStatus: extractedObject.order_status,
        paymentStatusDescription: extractedObject.status_message,
        userLoginId: extractedObject.billing_tel,
      };

      const updateSubscriptionPlanAPI = await axios.put(
        `${API_CONSTANTS}${updateSub}`,
        payload
      );
    } catch (error) {}
  };

  const createSubcription = async (plan) => {
    const createSubscriptionPlanUrl = "/subscription/createSubscriptionPlan";
    const createSubscriptionPlanPayload = {
      storePartyId: extractedObject.merchant_param3,
      partySubscription: {
        planId: extractedObject.merchant_param4.slice(0, 16),
        planTypeId: extractedObject.merchant_param4,
        amount: extractedObject.amount.replace("₹", "").trim(),
        paymentStatus: extractedObject?.order_status.toUpperCase(),
        paymentDateTime: dateTime,
        paymentTransactionId: extractedObject?.tracking_id,
        paymentReferenceId: extractedObject?.bank_ref_no,
        paymentMode: extractedObject?.payment_mode,
        // paymentMode: "UPI",
        subscriptionPaymentId: extractedObject?.merchant_param2,
        paymentOrderId: extractedObject?.order_id,
        originalPrice: plan?.originalPrice.replace("₹", "").trim(),
        taxPercentage: plan?.taxPercentage,
        discountedPrice: plan?.discountedPrice,
        discountType: plan?.discountType,
        taxPrice: plan?.taxPrice.replace("₹", "").trim(),
        roundOff: plan?.roundOff,
        planPrice: plan?.planPrice.replace("₹", "").trim(),
        discountValue: plan?.discountValue,
        paymentSource: (extractedObject.merchant_param5)?.split("-")[0],
        
      },
      paymentDetail: "",
    };

    const createSubscriptionPlanAPI = await axios.post(
      `${API_CONSTANTS}${createSubscriptionPlanUrl}`,
      createSubscriptionPlanPayload
    );

    if (createSubscriptionPlanAPI.status === 200) {
      let subId, subActId;

      subActId =
        createSubscriptionPlanAPI.data.content.partySubscription
          .subscriptionActivityId;
      subId =
        createSubscriptionPlanAPI.data.content.partySubscription.subscriptionId;
      // generateEInvoice(subId, subActId);
    }
  };

  useEffect(() => {
    if (
      extractedObject.order_status !== "Success"
      // || extractedObject.order_status !== "Initiated"
    ) {
      setPlanDetails({
        finalPrice: localStorage.getItem("amount"),
        planId: localStorage.getItem("planTypeId"),
        subscriptionId: localStorage.getItem("planId"),
        planValidity: localStorage.getItem("planValidity"),
      });
      setStoreDetails({
        partyId: localStorage.getItem("partyId"),
        storeName: localStorage.getItem("storeName"),
        ownerName: localStorage.getItem("ownerName"),
      });

      setMobNum(localStorage.getItem("userLoginId"));
    }
  }, [extractedObject.order_status]);

  // const generateEInvoice = async (subId, subActId) => {
  //   let url = "/subscription/create-subscription-invoice";
  //   let payload = {
  //     storePartyId: extractedObject.merchant_param3,
  //     partySubscription: {
  //       subscriptionId: subId,
  //       subscriptionActivityId: subActId,
  //     },
  //   };
  //   let createSubscriptionPlanAPI;
  //   createSubscriptionPlanAPI = await axios.post(
  //     `${API_CONSTANTS}${url}`,
  //     payload
  //   );
  //   if (createSubscriptionPlanAPI.data.errorCode === 200) {
  //     setInvoiceLink(createSubscriptionPlanAPI.data.content.path);

  //   }
  // };
  const deleteCookie = (cookieName) => {
    // document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    // document.cookie = ` yourCookieName=${""}; domain=localhost; path:/about `;
    document.cookie = ` yourCookieName=${""}; domain=.irevo.in; path:/about `;
    // )}; domain=localhost; path:/about `;
    // document.cookie = ` responseCookieName=${""}; domain=localhost; path:/about `;
    document.cookie = ` responseCookieName=${""}; domain=.irevo.in; path:/about `;
    // )}; domain=localhost; path:/about `;
  };

  const handleDeleteClick = () => {
    deleteCookie("yourCookieName");
  };

  useEffect(() => {
    if (
      // extractedObject.order_status === "Initiated" ||
      extractedObject.order_status === "Success"
    ) {
      localStorage.removeItem("partyId");
      localStorage.removeItem("storeName");
      localStorage.removeItem("storeDetails");
      localStorage.removeItem("amount");
      localStorage.removeItem("planId");
      localStorage.removeItem("planTypeId");
      localStorage.removeItem("userLoginId");
      localStorage.removeItem("ownerName");
    }
  }, [extractedObject.order_status]);

  useEffect(() => {
    if (invoiceLink) {
      // document.cookie = ` yourCookieName=${""}; domain=localhost; path:/about `;
      document.cookie = ` yourCookieName=${""}; domain=.irevo.in; path:/about `;
      // )}; domain=localhost; path:/about `;
      // document.cookie = ` responseCookieName=${""}; domain=localhost; path:/about `;
      document.cookie = ` responseCookieName=${""}; domain=.irevo.in; path:/about `;
      // )}; domain=localhost; path:/about `;
    }
  }, [invoiceLink]);

  const appSetTimeOut = () => {
    setTimeout(() => {
      redirectAndroid();
    }, 3000);
  };

  const clickGoBackButton = () => {
    window.location.replace("https://irevo.in/");
  };

  return (
    <>
      {(extractedObject.merchant_param5)?.split("-")[0] === "Web" && <Header />}
      <div className="center text-center">
        <div
          className={
            !extractedObject.order_status ? "" : "paymentCard px-1 py-2"
          }
        >
          {extractedObject.order_status === "Success" 
          
          // || extractedObject.order_status === "Initiated" //@Shar-DEV FOR DEV ENV
          
          ? (
             
            <>
              <div className="mt-3 mb-2">
                <img
                  src={SuccessfulPayment}
                  alt="Successful Payment"
                  width={160}
                  height={160}
                />
              </div>
              <div className="subscriptionSuccessAlign mb-2">
                Congratulations Subscription Completed
              </div>

              <div className="TransactionIdAlign mb-2">
                Transaction Id {extractedObject.order_id}/
                {extractedObject.tracking_id}
              </div>

              {(extractedObject.merchant_param5)?.split("-")[0] === "Web" && (
                <>
                  <div className="InstallAppAlign mt-2 mb-2">
                    Install our app now
                  </div>

                  <a
                    href="https://play.google.com/store/search?q=irevo&c=apps&hl=en-IN"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={Playstore} height={55} alt="playstore" />
                  </a>

                  <div className="registeredNumberAlign my-1">
                    Login with the registered mobile number
                  </div>

                  <div className="mobileNumberAlign mb-1">+91 {mobNum}</div>

                  {invoiceLink ? (
                    <>
                      <a
                        className="button"
                        style={{ color: "#000", alignContent: "center" }}
                        href={`${API_CONSTANTS}${invoiceLink}`}
                        download
                      >
                        Download Invoice
                      </a>
                    </>
                  ) : (
                    <div>
                      <span className="invoiceloadingAlign">
                        Invoice Loading
                      </span>
                      <span>
                        <BeatLoader color="#6c757d" size={8} />
                      </span>
                    </div>
                  )}

                  <div className="text-danger mt-2 px-3">
                    <span className="fw-bolder">Note:</span>&nbsp;
                    <span className="fw-normal">
                      Do not refresh the page while processing your data
                    </span>
                  </div>

                  {invoiceLink && (
                    <button
                      className="goBackButton mt-3"
                      onClick={() => clickGoBackButton()}
                    >
                      Go to Home
                    </button>
                  )}
                </>
              )}

              {(extractedObject.merchant_param5)?.split("-")[0] === "App" &&
                (invoiceLink ? (
                  appSetTimeOut()
                ) : (
                  <>
                    <div className="mt-2">
                      <span className="invoiceloadingAlign">Loading</span>
                      <span>
                        <BeatLoader color="#6c757d" size={8} />
                      </span>
                    </div>
                  </>
                ))}

              {(extractedObject.merchant_param5)?.split("-")[0] === "App" && (
                <div className="text-danger mt-2 px-3">
                  <span className="fw-bolder">Note:</span>&nbsp;
                  <span className="fw-normal">
                    Do not press back button while processing your data
                  </span>
                </div>
              )}
            </>
          ) : extractedObject.order_status === "Failure" ||
            extractedObject.order_status === "Aborted" ||
            extractedObject.order_status === "Invalid" ||
            extractedObject.order_status === "Timeout" ||
            extractedObject.order_status === "Awaited" ? (
            <>
              <div className="my-4">
                <img
                  src={PaymentIncomplete}
                  alt="Payment Incomplete"
                  width={200}
                  height={200}
                />
              </div>

              <div className="subscriptionSuccessAlign mb-3">Payment Error</div>

              <div className="paymentUnsuccessfulAlign mb-3 mx-1">
                Payment unsuccessful. {extractedObject.status_message}
              </div>
              <div className="my-3">
                <button
                  className="retryButton py-1 fw-bold"
                  onClick={() => {
                    navigate("/subscription");
                    handleDeleteClick();
                  }}
                >
                  Retry
                </button>
              </div>

              <div className="text-danger mt-2 px-3">
                <span className="fw-bolder">Note:</span>&nbsp;
                <span className="fw-normal">
                  Do not press back button or refresh the page while processing
                  your data
                </span>
              </div>
            </>
          ) : (
            ""
          )}
        </div>

        {!extractedObject.order_status && (
          <button
            className="goBackButton mt-3"
            onClick={() => clickGoBackButton()}
          >
            Go to Home
          </button>
        )}

        <ToastContainer
          position="bottom-center"
          autoClose={1500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
          closeButton={false}
          className="p-4"
        />
      </div>
      {(extractedObject.merchant_param5)?.split("-")[0] === "Web" && <Footer />}
    </>
  );
};

export default SubscriptionSuccess;
