import { useNavigate } from "react-router-dom";
import { API_CONSTANTS } from "../../constants/api-constants";
import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import * as appmetric from "../appmetrics/index.js";
import BackIcon from "../../assets/images/BackIcon";
import paymentContext from "../../context/paymentContext";
import "./index.css";
import Header from "../header/index.jsx";
import Footer from "../footer/index.jsx";

const ComparePlan = () => {
  const [chooseDIYPlan, setChooseDIYPlan] = useState(false);
  const [chooseSilverPlan, setChooseSilverPlan] = useState(false);
  const [chooseGoldPlan, setChooseGoldPlan] = useState(false);
  const [choosePlatinumPlan, setChoosePlatinumPlan] = useState(false);
  const [chooseButtonColor, setChooseButtonColor] = useState("");
  const [subscriptionData, setSubscriptionData] = useState([]);
  const [ChoosedPlanValue, setChoosedPlanValue] = useState([]);
  const [ChoosedPlanMonth, setChoosedPlanMonth] = useState('');

  const navigate = useNavigate();
  const {
    src,
    mobNum,
    storeDetails,
    planDetails,
    setPlanDetails,
    setSelectedPlanOrignialPrice
  } = useContext(paymentContext);

  useEffect(() => {
    subscriptionDetails();
  }, []);


  const subscriptionSelectedPlan = (plan) => {
    // if (plan.subscriptionId === "RET_SUBSCR_PLAN1") {
    //   setChooseDIYPlan(true);
    //   setChooseSilverPlan(false);
    //   setChooseGoldPlan(false);
    //   setChoosePlatinumPlan(false);
    //   setChooseButtonColor('DIYPlanButton')
    //   let choosePlan = [];
    //   subscriptionData.map((plan) => {
    //     if (plan.subscriptionId === "RET_SUBSCR_PLAN1") {
    //       choosePlan.push(plan);
    //       setChoosedPlanValue(choosePlan)
    //     }
    //   });

    //   // RET_SUBSCR_PLAN1
    //   if (plan.planId === "RET_SUBSCR_PLAN1_3M") {
    //     appmetric.addAppMetric("8414", null, storeDetails.partyId, mobNum);
    //     setChoosedPlanMonth(plan.planId);
    //   }

    //   if (plan.planId === "RET_SUBSCR_PLAN1_6M") {
    //     appmetric.addAppMetric("8415", null, storeDetails.partyId, mobNum);
    //     setChoosedPlanMonth(plan.planId);
    //   }

    //   if (plan.planId === "RET_SUBSCR_PLAN1_12M") {
    //     appmetric.addAppMetric("8416", null, storeDetails.partyId, mobNum);
    //     setChoosedPlanMonth(plan.planId);
    //   }
    // }

    // RET_SUBSCR_PLAN2
    if (plan.subscriptionId === "RET_SUBSCR_PLAN2") {
      setChooseDIYPlan(false);
      setChooseSilverPlan(true);
      setChooseGoldPlan(false);
      setChoosePlatinumPlan(false);
      setChooseButtonColor('silverPlanButton')
      let choosePlan = [];
      subscriptionData.map((plan) => {
        if (plan.subscriptionId === "RET_SUBSCR_PLAN2") {
          choosePlan.push(plan);
          setChoosedPlanValue(choosePlan)
        }
      });

      // RET_SUBSCR_PLAN2
      if (plan.planId === "RET_SUBSCR_PLAN2_3M") {
        appmetric.addAppMetric("8417", null, storeDetails.partyId, mobNum);
        setChoosedPlanMonth(plan.planId);
      }

      if (plan.planId === "RET_SUBSCR_PLAN2_6M") {
        appmetric.addAppMetric("8418", null, storeDetails.partyId, mobNum);
        setChoosedPlanMonth(plan.planId);
      }

      if (plan.planId === "RET_SUBSCR_PLAN2_12M") {
        appmetric.addAppMetric("8419", null, storeDetails.partyId, mobNum);
        setChoosedPlanMonth(plan.planId);
      }

    }

    // RET_SUBSCR_PLAN3
    if (plan.subscriptionId === "RET_SUBSCR_PLAN3") {
      setChooseDIYPlan(false);
      setChooseSilverPlan(false);
      setChooseGoldPlan(true);
      setChoosePlatinumPlan(false);
      setChooseButtonColor('goldPlanButton')
      let choosePlan = [];
      subscriptionData.map((plan) => {
        if (plan.subscriptionId === "RET_SUBSCR_PLAN3") {
          choosePlan.push(plan);
          setChoosedPlanValue(choosePlan)
        }
      });

      // RET_SUBSCR_PLAN3
      if (plan.planId === "RET_SUBSCR_PLAN3_3M") {
        appmetric.addAppMetric("8420", null, storeDetails.partyId, mobNum);
        setChoosedPlanMonth(plan.planId);
      }

      if (plan.planId === "RET_SUBSCR_PLAN3_6M") {
        appmetric.addAppMetric("8421", null, storeDetails.partyId, mobNum);
        setChoosedPlanMonth(plan.planId);
      }

      if (plan.planId === "RET_SUBSCR_PLAN3_12M") {
        appmetric.addAppMetric("8422", null, storeDetails.partyId, mobNum);
        setChoosedPlanMonth(plan.planId);
      }
    }

    // RET_SUBSCR_PLAN4
    if (plan.subscriptionId === "RET_SUBSCR_PLAN4") {
      setChooseDIYPlan(false);
      setChooseSilverPlan(false);
      setChooseGoldPlan(false);
      setChoosePlatinumPlan(true);
      setChooseButtonColor('platinumPlanButton')
      let choosePlan = [];
      subscriptionData.map((plan) => {
        if (plan.subscriptionId === "RET_SUBSCR_PLAN4") {
          choosePlan.push(plan);
          setChoosedPlanValue(choosePlan)
        }
      });

      // RET_SUBSCR_PLAN4
      if (plan.planId === "RET_SUBSCR_PLAN4_3M") {
        appmetric.addAppMetric("8424", null, storeDetails.partyId, mobNum);
        setChoosedPlanMonth(plan.planId);
      }

      if (plan.planId === "RET_SUBSCR_PLAN4_6M") {
        appmetric.addAppMetric("8425", null, storeDetails.partyId, mobNum);
        setChoosedPlanMonth(plan.planId);
      }

      if (plan.planId === "RET_SUBSCR_PLAN4_12M") {
        appmetric.addAppMetric("8426", null, storeDetails.partyId, mobNum);
        setChoosedPlanMonth(plan.planId);
      }
    }
  }

  const subscriptionDetails = async () => {
    try {
      const subscriptionDetailsUrl = `/subscription/subscriptionDetails?storePartyId=${storeDetails.partyId}`;
      const response = await axios.get(
        `${API_CONSTANTS}${subscriptionDetailsUrl}`
      );
      setSubscriptionData(
        response.data.content.subscriptionPlanDetails
      );

      subscriptionSelectedPlan(planDetails);
      let PlanValue = [];
      response.data.content.subscriptionPlanDetails.filter((plan) => {
        if (plan.subscriptionId !== "RET_SUBSCR_PLAN1") {
          if (plan.subscriptionId === planDetails.subscriptionId) {
            PlanValue.push(plan);
            setChoosedPlanValue(PlanValue);
          }
        }
      });
    } catch (error) {
      console.log("error", error);
    }
  };



  const ChoosePlanButton = (planType) => {
    // if (planType === "DIY") {
    //   appmetric.addAppMetric("8409", null, storeDetails.partyId, mobNum);
    //   setChooseDIYPlan(true);
    //   setChooseSilverPlan(false);
    //   setChooseGoldPlan(false);
    //   setChoosePlatinumPlan(false);
    //   setChooseButtonColor('DIYPlanButton')
    //   let choosePlan = [];
    //   subscriptionData.map((plan) => {
    //     if (plan.subscriptionId === "RET_SUBSCR_PLAN1") {
    //       choosePlan.push(plan);
    //       setChoosedPlanValue(choosePlan)
    //     }
    //   });
    // }

    if (planType === "Silver") {
      appmetric.addAppMetric("8410", null, storeDetails.partyId, mobNum);
      setChooseDIYPlan(false);
      setChooseSilverPlan(true);
      setChooseGoldPlan(false);
      setChoosePlatinumPlan(false);
      setChooseButtonColor('silverPlanButton')
      let choosePlan = [];
      subscriptionData.map((plan) => {
        if (plan.subscriptionId === "RET_SUBSCR_PLAN2") {
          choosePlan.push(plan);
          setChoosedPlanValue(choosePlan)
        }
      });
    }

    if (planType === "Gold") {
      appmetric.addAppMetric("8411", null, storeDetails.partyId, mobNum);
      setChooseDIYPlan(false);
      setChooseSilverPlan(false);
      setChooseGoldPlan(true);
      setChoosePlatinumPlan(false);
      setChooseButtonColor('goldPlanButton')
      let choosePlan = [];
      subscriptionData.map((plan) => {
        if (plan.subscriptionId === "RET_SUBSCR_PLAN3") {
          choosePlan.push(plan);
          setChoosedPlanValue(choosePlan)
        }
      });
    }

    if (planType === "Platinum") {
      appmetric.addAppMetric("8423", null, storeDetails.partyId, mobNum);
      setChooseDIYPlan(false);
      setChooseSilverPlan(false);
      setChooseGoldPlan(false);
      setChoosePlatinumPlan(true);
      setChooseButtonColor('platinumPlanButton')
      let choosePlan = [];
      subscriptionData.map((plan) => {
        if (plan.subscriptionId === "RET_SUBSCR_PLAN4") {
          choosePlan.push(plan);
          setChoosedPlanValue(choosePlan)
        }
      });
    }
  }
  const clickPlanButton = (planType) => {
    // if (planType === "RET_SUBSCR_PLAN1_3M") {
    //   appmetric.addAppMetric("8414", null, storeDetails.partyId, mobNum);
    //   setChoosedPlanMonth(planType);
    // }

    // if (planType === "RET_SUBSCR_PLAN1_6M") {
    //   appmetric.addAppMetric("8415", null, storeDetails.partyId, mobNum);
    //   setChoosedPlanMonth(planType);
    // }

    // if (planType === "RET_SUBSCR_PLAN1_12M") {
    //   appmetric.addAppMetric("8416", null, storeDetails.partyId, mobNum);
    //   setChoosedPlanMonth(planType);
    // }
    if (planType === "RET_SUBSCR_PLAN2_3M") {
      appmetric.addAppMetric("8417", null, storeDetails.partyId, mobNum);
      setChoosedPlanMonth(planType);
    }

    if (planType === "RET_SUBSCR_PLAN2_6M") {
      appmetric.addAppMetric("8418", null, storeDetails.partyId, mobNum);
      setChoosedPlanMonth(planType);
    }

    if (planType === "RET_SUBSCR_PLAN2_12M") {
      appmetric.addAppMetric("8419", null, storeDetails.partyId, mobNum);
      setChoosedPlanMonth(planType);
    }
    if (planType === "RET_SUBSCR_PLAN3_3M") {
      appmetric.addAppMetric("8420", null, storeDetails.partyId, mobNum);
      setChoosedPlanMonth(planType);
    }

    if (planType === "RET_SUBSCR_PLAN3_6M") {
      appmetric.addAppMetric("8421", null, storeDetails.partyId, mobNum);
      setChoosedPlanMonth(planType);
    }

    if (planType === "RET_SUBSCR_PLAN3_12M") {
      appmetric.addAppMetric("8422", null, storeDetails.partyId, mobNum);
      setChoosedPlanMonth(planType);
    }

    if (planType === "RET_SUBSCR_PLAN4_3M") {
      appmetric.addAppMetric("8424", null, storeDetails.partyId, mobNum);
      setChoosedPlanMonth(planType);
    }

    if (planType === "RET_SUBSCR_PLAN4_6M") {
      appmetric.addAppMetric("8425", null, storeDetails.partyId, mobNum);
      setChoosedPlanMonth(planType);
    }

    if (planType === "RET_SUBSCR_PLAN4_12M") {
      appmetric.addAppMetric("8426", null, storeDetails.partyId, mobNum);
      setChoosedPlanMonth(planType);
    }

  };

  const clickSubscribeButton = () => {
    appmetric.addAppMetric("8412", null, storeDetails.partyId, mobNum);
    subscriptionData.filter((data) => {
      if (ChoosedPlanMonth === data.planId) {
        setPlanDetails(data);
        setSelectedPlanOrignialPrice(data.originalPrice);

      }
    })
    navigate("/subscription");
  };

  const clickComparePlanBackButton = () => {
    appmetric.addAppMetric("8413", null, storeDetails.partyId, mobNum);
    navigate("/subscription");
  };

  return (
    <>
      {src === "Web" && <Header />}
      <div className="center text-center">
        <div className="paymentCard px-2">
          <div className="d-flex justify-content-between mt-2">
            <div role="button" onClick={() => clickComparePlanBackButton()}>
              <BackIcon />
            </div>
          </div>

          <div className="comparePlanAlign">Compare plan</div>
          <div className="themeColor mt-1">Select Plan to know the Pricing</div>

          <div className="row mt-3">
            <div className="d-flex row compareTextOverflowScrollAlign">
              <div className="col-6 planFont">
                <div className="comparePlanHeightAlign"></div>
                <div className="comparePlanHeight1">
                  We will create your Facebook and Instagram Pages for your pharmacy
                </div>
                <div style={{ color: "#8080804f" }}>----------------------</div>
                <div className="comparePlanHeight2">
                  We will set up your Google Business account
                </div>
                <div style={{ color: "#8080804f" }}>----------------------</div>
                <div className="comparePlanHeight3">
                  We will create your WhatsApp account for your pharmacy
                </div>
                <div style={{ color: "#8080804f" }}>----------------------</div>
                <div className="comparePlanHeight4">
                  WhatsApp auto response message
                </div>
                <div style={{ color: "#8080804f" }}>----------------------</div>
                <div className="comparePlanHeight5">
                  Transactional & Customer service messages through Whatsapp
                </div>
                <div style={{ color: "#8080804f" }}>----------------------</div>
                <div className="comparePlanHeight6">
                  Artwork Templates (New bill book format, logo, paper bags design,etc)
                </div>
                <div style={{ color: "#8080804f" }}>----------------------</div>
                <div className="comparePlanHeight7" >
                  We will create your Facebook Business account, which will give you an additional benefits of more online presence( run more campigns, help in SEO, 2post /week, to keep page lively)
                </div>
                <div style={{ color: "#8080804f" }}>----------------------</div>
                <div className="comparePlanHeight8">
                  Whatsapp Community Creation
                </div>
                <div style={{ color: "#8080804f" }}>----------------------</div>
                <div className="comparePlanHeight9">
                  Welcoming Kit with Branded Merchandise
                </div>
                <div style={{ color: "#8080804f" }}>----------------------</div>
                <div className="comparePlanHeight10">
                  No. of Campaigns we will run, that you can create from a library of  offer templates
                </div>
                <div style={{ color: "#8080804f" }}>----------------------</div>
                <div className="comparePlanHeight11">
                  No of impressions/customers we shall give you a reach of, in your neighbourhood through the campaigns on Facebook and Instagram per month
                </div>
                <div style={{ color: "#8080804f" }}>----------------------</div>
                <div className="comparePlanHeight12">
                  Caps for Google SEO(no. of products)
                </div>
                <div style={{ color: "#8080804f" }}>----------------------</div>
                <div className="comparePlanHeight13">
                  We will do standard post created by tarnea, related to his pharmacy business, regarding health information/ festivals content on your Facebook and Instagram Google my business accounts/year
                </div>
                <div style={{ color: "#8080804f" }}>----------------------</div>
                <div className="comparePlanHeight14">
                  No. of offer templates
                </div>
                <div style={{ color: "#8080804f" }}>----------------------</div>
                <div className="comparePlanHeight15">
                  Efficient Customer Experience Response under
                </div>
                <div style={{ color: "#8080804f" }}>----------------------</div>
                <div className="comparePlanHeight16">
                  No. of greeting messages he can send in a month
                </div>
                <div style={{ color: "#8080804f" }}>----------------------</div>
                <div className="comparePlanHeight17">
                  Pay just per month +GST/-
                </div>
              </div>
              <div className="col-6 planFont">
                <div className="row">
                  <div className={chooseSilverPlan ? "silverBorder col-4 p-0 pb-2 pointer" : "col-4 p-0 pointer"} onClick={() => ChoosePlanButton("Silver")}>
                    <div className={chooseSilverPlan ? "silverBackground comparePlanHeightAlign planFont" : "comparePlanHeightAlign planFont"}>Silver</div>
                    <div className="displayFlexAlign selectedPlanHeight"><i className="fa-solid fa-check silver"></i></div>
                    <div className="displayFlexAlign selectedPlanHeight1"><i className="fa-solid fa-check silver"></i></div>
                    <div className="displayFlexAlign selectedPlanHeight2"><i className="fa-solid fa-check silver"></i></div>
                    <div className="displayFlexAlign selectedPlanHeight3"><i className="fa-solid fa-check silver"></i></div>
                    <div className="displayFlexAlign selectedPlanHeight4"><i className="fa-solid fa-check silver"></i></div>
                    <div className="displayFlexAlign selectedPlanHeight5"><i className="fa-solid fa-xmark silver"></i></div>
                    <div className="displayFlexAlign selectedPlanHeight6"><i className="fa-solid fa-xmark silver"></i></div>
                    <div className="displayFlexAlign selectedPlanHeight7"><i className="fa-solid fa-xmark silver"></i></div>
                    <div className="displayFlexAlign selectedPlanHeight8"><i className="fa-solid fa-xmark silver"></i></div>
                    <div className="displayFlexAlign selectedPlanHeight9 silver">3</div>
                    <div className="displayFlexAlign selectedPlanHeight10 silver">3000</div>
                    <div className="displayFlexAlign selectedPlanHeight11 silver">50</div>
                    <div className="displayFlexAlign selectedPlanHeight12 silver">6</div>
                    <div className="displayFlexAlign selectedPlanHeight13 silver">10</div>
                    <div className="displayFlexAlign selectedPlanHeight14 silver">3 Hours</div>
                    <div className="displayFlexAlign selectedPlanHeight15 silver">200</div>
                    <div className="displayFlexAlign selectedPlanHeight16 silver">500</div>

                  </div>
                  <div className={chooseGoldPlan ? "goldBorder col-4 p-0 pb-2 pointer" : "col-4 p-0 pointer"} onClick={() => ChoosePlanButton("Gold")}>
                    <div className={chooseGoldPlan ? "goldBackground comparePlanHeightAlign planFont" : "comparePlanHeightAlign planFont"}>Gold</div>
                    <div className="displayFlexAlign selectedPlanHeight"><i className="fa-solid fa-check gold"></i></div>
                    <div className="displayFlexAlign selectedPlanHeight1"><i className="fa-solid fa-check gold"></i></div>
                    <div className="displayFlexAlign selectedPlanHeight2"><i className="fa-solid fa-check gold"></i></div>
                    <div className="displayFlexAlign selectedPlanHeight3"><i className="fa-solid fa-check gold"></i></div>
                    <div className="displayFlexAlign selectedPlanHeight4"><i className="fa-solid fa-check gold"></i></div>
                    <div className="displayFlexAlign selectedPlanHeight5"><i className="fa-solid fa-check gold"></i></div>
                    <div className="displayFlexAlign selectedPlanHeight6"><i className="fa-solid fa-xmark gold"></i></div>
                    <div className="displayFlexAlign selectedPlanHeight7"><i className="fa-solid fa-xmark gold"></i></div>
                    <div className="displayFlexAlign selectedPlanHeight8"><i className="fa-solid fa-xmark gold"></i></div>
                    <div className="displayFlexAlign selectedPlanHeight9 gold">10</div>
                    <div className="displayFlexAlign selectedPlanHeight10 gold">20000</div>
                    <div className="displayFlexAlign selectedPlanHeight11 gold">150</div>
                    <div className="displayFlexAlign selectedPlanHeight12 gold">15</div>
                    <div className="displayFlexAlign selectedPlanHeight13 gold">50</div>
                    <div className="displayFlexAlign selectedPlanHeight14 gold">1 Hour</div>
                    <div className="displayFlexAlign selectedPlanHeight15 gold">500</div>
                    <div className="displayFlexAlign selectedPlanHeight16 gold">2000</div>
                  </div>
                  <div className={choosePlatinumPlan ? "platinumBorder col-4 p-0 pb-2 pointer" : "col-4 p-0 pointer"} onClick={() => ChoosePlanButton("Platinum")}>
                    <div className={choosePlatinumPlan ? "platinumBackground comparePlanHeightAlign planFont" : "comparePlanHeightAlign planFont"}>Platinum</div>
                    <div className="displayFlexAlign selectedPlanHeight"><i className="fa-solid fa-check platinum"></i></div>
                    <div className="displayFlexAlign selectedPlanHeight1"><i className="fa-solid fa-check platinum"></i></div>
                    <div className="displayFlexAlign selectedPlanHeight2"><i className="fa-solid fa-check platinum"></i></div>
                    <div className="displayFlexAlign selectedPlanHeight3"><i className="fa-solid fa-check platinum"></i></div>
                    <div className="displayFlexAlign selectedPlanHeight4"><i className="fa-solid fa-check platinum"></i></div>
                    <div className="displayFlexAlign selectedPlanHeight5"><i className="fa-solid fa-check platinum"></i></div>
                    <div className="displayFlexAlign selectedPlanHeight6"><i className="fa-solid fa-check platinum"></i></div>
                    <div className="displayFlexAlign selectedPlanHeight7"><i className="fa-solid fa-check platinum"></i></div>
                    <div className="displayFlexAlign selectedPlanHeight8"><i className="fa-solid fa-check platinum"></i></div>
                    <div className="displayFlexAlign selectedPlanHeight9 platinum">25</div>
                    <div className="displayFlexAlign selectedPlanHeight10 platinum">50000</div>
                    <div className="displayFlexAlign selectedPlanHeight11 platinum">500</div>
                    <div className="displayFlexAlign selectedPlanHeight12 platinum">25</div>
                    <div className="displayFlexAlign selectedPlanHeight13 platinum">200</div>
                    <div className="displayFlexAlign selectedPlanHeight14 platinum">15 Minutes</div>
                    <div className="displayFlexAlign selectedPlanHeight15 platinum">Unlimited</div>
                    <div className="displayFlexAlign selectedPlanHeight16 platinum">4000</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-around">
            {ChoosedPlanValue.map((planData) => {
              return (
                <div className="mt-2">
                  <div className="position-relative">
                    <button
                      className={ChoosedPlanMonth === planData.planId ? chooseButtonColor : "planButton"}
                      onClick={() => clickPlanButton(planData.planId)}
                    >
                      {planData.discountValue > 0 ?
                        <div className="d-flex justify-content-end my-1"><del>{planData.planPrice.replace(" ","")}</del><div className="ms-1">
                          <span className="offerValue">{planData.discountValue}%</span></div></div>
                        :
                        <div className="emptyDiv"></div>
                      }
                      <div>{planData.originalPrice.replace(" ","")}+GST</div>
                      <div>{planData.planValidity}</div>
                    </button>
                  </div>
                </div>)
            })}
          </div>

          <div style={{ marginTop: "10px" }}>
            <button
              className="subscribeButton py-1"
              onClick={() => clickSubscribeButton()}
            >
              Subscribe
            </button>
          </div>
        </div>
      </div>
      {src === "Web" && <Footer />}
    </>
  );
};

export default ComparePlan;
